import styled, { css } from 'styled-components/macro';

export const hideOnMobile = css`
  ${({ theme }) => theme.media.phone`
    display: none;
  `}
`;

export const hideOnDesktopAndTablet = css`
  ${({ theme }) => theme.media.desktopAndTablet`
    display: none;
  `}
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 85%;
  margin: 72px auto;
  padding: 12px;
  ${({ theme }) => theme.media.tablet`
    width: 95%;
  `};
  ${({ theme }) => theme.media.phone`
    flex-direction: column;
    width: 100%;
    margin: 0 0 56px;
    padding: 0 0 36px;
    background-color: ${theme.colors.backgroundGrey};
  `};
`;

export const Step = styled.div`
  position: relative;
  display: inline-flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  /* width: 31%; */
  /* max-width: 390px; */
  flex-basis: 26%;
  min-height: 300px;
  margin: 12px;
  padding: 40px 35px;
  box-sizing: border-box;
  background-color: ${({ backgroundColor, theme }) => theme.colors[backgroundColor]};

  &::after {
    ${({ withArrow }) => withArrow && 'content:" ";'}
    position: absolute;
    ${({ pose }) => pose}: -8px;
    /* left: -8px; */
    ${({ isVertical }) => (isVertical ? 'left' : 'top')}: calc(50% - 8px);
    width: 16px;
    height: 16px;
    transform: rotate(45deg);
    background-color: ${({ backgroundColor, theme }) => theme.colors[backgroundColor]};
  }
  ${({ theme, mobileOrder, backgroundColor }) => theme.media.phone`
    order: ${mobileOrder};
    width: 100%;
    max-width: unset;
    margin: unset;
    padding: 20px 24px;
    min-height: unset;
    background-color: transparent;
    &::after {
      content: unset;
    }
    & div {
      color: ${theme.colors.white};
      background-color: ${theme.colors[backgroundColor]};
      border: 8px solid ${theme.colors[backgroundColor]}33;
      box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.04);
    }
  `};
`;

export const StepNumber = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-clip: padding-box;
  background-color: #ffffff;
  border: 8px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.04);
  border-radius: 50%;
  width: 64px;
  height: 64px;
  font-family: CoconNextArabic;
  font-size: 32px;
  margin-bottom: 16px;
  color: ${({ theme }) => theme.colors.darkBlue};
`;

export const TitleH3 = styled.h3`
  ${({ theme }) => theme.textSize.h1};
  text-align: center;
  color: ${({ theme }) => theme.colors.white};
  ${({ theme }) => theme.media.phone`
    ${theme.textSize.h2};
    font-weight: 500;
    width: 75%;
    color: ${theme.colors.darkBlue};
  `};
`;

export const StepTitle = styled.h4`
  ${({ theme }) => theme.textSize.h3};
  text-align: center;
  color: ${({ theme }) => theme.colors.white};
  margin-bottom: 8px;
  ${({ theme }) => theme.media.phone`
    color: ${theme.colors.darkBlue};
  `};
`;

export const StepDescription = styled.p`
  ${({ theme }) => theme.textSize.p2};
  text-align: center;
  color: ${({ theme }) => theme.colors.white};
  ${({ theme }) => theme.media.phone`
    color: ${theme.colors.darkBlue};
  `};
`;
