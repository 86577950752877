import React from 'react';
import PropTypes from 'prop-types';
import { CSSTransitionGroup } from 'react-transition-group';
import { PreventBodyScrolling, DrawerContent } from './Drawer.style';

class Drawer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: props.open,
      opening: props.open,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.open !== undefined &&
      nextProps.open != null &&
      nextProps.open !== prevState.open
    ) {
      return {
        open: nextProps.open,
      };
    }
    return null;
  }

  onClose = () => {
    this.setState({ open: false }, () => {
      this.props.onClose();
    });
  };

  render() {
    const { open, opening } = this.state;
    return (
      <>
        <PreventBodyScrolling isDrawerOpen={open} />
        <CSSTransitionGroup
          transitionName="side-slide"
          transitionEnterTimeout={200}
          transitionLeaveTimeout={200}
        >
          <DrawerContent opening={opening} open={open} key={`animation_drawer_${open}`}>
            {this.props.children}
          </DrawerContent>
        </CSSTransitionGroup>
      </>
    );
  }
}

Drawer.propTypes = {
  children: PropTypes.node.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default Drawer;
