import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination, Navigation, Autoplay } from 'swiper/core';

import 'swiper/swiper.min.css';
import 'swiper/components/pagination/pagination.min.css';
import 'swiper/components/navigation/navigation.min.css';

import { Title } from './HomeCarousel.style';
import './style.css';

SwiperCore.use([Pagination, Navigation, Autoplay]);

const HomeCarousel = () => {
  return (
    <Swiper
      loop
      navigation
      pagination
      autoplay={{
        delay: 3000,
        disableOnInteraction: false,
      }}
    >
      <SwiperSlide>
        <img
          id="position-35"
          src="https://uhia.vezeeta.com/assets/uhia/images/image-1.png"
          alt="background 1"
        />
        <Title>٣٠٠ جنيه حد أقصى لأي عملية جراحية</Title>
      </SwiperSlide>
      <SwiperSlide>
        <img
          id="position-49"
          src="https://uhia.vezeeta.com/assets/uhia/images/image-2.png"
          alt="background 2"
        />
        <Title maxWidth="700px">سجل الآن….جميع الأمراض تحت مظلة نظام التأمين الصحي الشامل</Title>
      </SwiperSlide>
      <SwiperSlide>
        <img src="https://uhia.vezeeta.com/assets/uhia/images/image-4.png" alt="background 3" />
        <Title maxWidth="430px">من حقك تختار جهة العلاج</Title>
      </SwiperSlide>
      <SwiperSlide>
        <img src="https://uhia.vezeeta.com/assets/uhia/images/image-3.png" alt="background 4" />
        <Title maxWidth="440px">لا يوجد حد للإنفاق العلاجي المُقدم للمواطنين</Title>
      </SwiperSlide>
    </Swiper>
  );
};

export default HomeCarousel;
