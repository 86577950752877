import React, { useState } from 'react';
import Icon from 'components/Icon';
import luxor from 'assets/images/luxor.png';
import portSaid from 'assets/images/port-said.png';
import {
  Container,
  Column,
  ExpandButton,
  StatisticsWrapper,
  StatisticCard,
  StatisticsNumber,
  StatisticsDescription,
  ColumnNote,
  ExpandButtonMobile,
  extendImage,
  extendIcons,
  extendArrowIcon,
} from './InfoGraph.style';

const InfoGraph = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <Container>
      <Column className="collapsible">
        <Icon
          isImageIcon
          src="https://uhia.vezeeta.com/assets/uhia/images/luxor.png"
          size={256}
          extendStyle={extendImage}
        />
        <StatisticsWrapper isExpanded={isExpanded}>
          <StatisticCard>
            <Icon
              icon="e907"
              size={24}
              color={'white'}
              bkgColor={'lightBlue'}
              extendStyle={extendIcons}
            />
            <StatisticsNumber>1 002 552</StatisticsNumber>
            <StatisticsDescription>عدد المواطنين الذين تم تسجيلهم</StatisticsDescription>
          </StatisticCard>
          <StatisticCard>
            <Icon
              icon="e90a"
              size={24}
              color={'white'}
              bkgColor={'redRose'}
              extendStyle={extendIcons}
            />
            <StatisticsNumber>338 905</StatisticsNumber>
            <StatisticsDescription>عدد الفحوصات التي تم اجراؤها</StatisticsDescription>
          </StatisticCard>
          <StatisticCard>
            <Icon
              icon="e906"
              size={24}
              color={'white'}
              bkgColor={'redRose'}
              extendStyle={extendIcons}
            />
            <StatisticsNumber>4</StatisticsNumber>
            <StatisticsDescription>مقدمي الخدمة من القطاع الخاص المسجلين</StatisticsDescription>
          </StatisticCard>
          <StatisticCard>
            <Icon
              icon="e908"
              size={24}
              color={'white'}
              bkgColor={'darkBlue'}
              extendStyle={extendIcons}
            />
            <StatisticsNumber>866 238</StatisticsNumber>
            <StatisticsDescription>نسبة من تم تسجيلهم بالنظام</StatisticsDescription>
          </StatisticCard>
          <StatisticCard>
            <Icon
              icon="e909"
              size={24}
              color={'white'}
              bkgColor={'darkBlue'}
              extendStyle={extendIcons}
            />
            <StatisticsNumber>6</StatisticsNumber>
            <StatisticsDescription>المستشفيات التي تم تطويرها</StatisticsDescription>
          </StatisticCard>
          <StatisticCard>
            <Icon
              icon="e905"
              size={24}
              color={'white'}
              bkgColor={'lightBlue'}
              extendStyle={extendIcons}
            />
            <StatisticsNumber>59</StatisticsNumber>
            <StatisticsDescription>الوحدات و المراكز التي تم تطويرها</StatisticsDescription>
          </StatisticCard>
          <ColumnNote>عدد سكان المحافظة 769,499 مواطن</ColumnNote>
        </StatisticsWrapper>
        {!isExpanded && (
          <ExpandButtonMobile onClick={() => setIsExpanded(true)}>
            عرض إحصائيات
            <Icon icon="e92c" size="10" extendStyle={extendArrowIcon} />
          </ExpandButtonMobile>
        )}
      </Column>
      <Column className="collapsible">
        <Icon
          isImageIcon
          src="https://uhia.vezeeta.com/assets/uhia/images/port-said.png"
          size={256}
          extendStyle={extendImage}
        />
        <StatisticsWrapper isExpanded={isExpanded}>
          <StatisticCard>
            <Icon
              icon="e907"
              size={24}
              color={'white'}
              bkgColor={'lightBlue'}
              extendStyle={extendIcons}
            />
            <StatisticsNumber>752 413</StatisticsNumber>
            <StatisticsDescription>عدد المواطنين الذين تم تسجيلهم</StatisticsDescription>
          </StatisticCard>
          <StatisticCard>
            <Icon
              icon="e90a"
              size={24}
              color={'white'}
              bkgColor={'redRose'}
              extendStyle={extendIcons}
            />
            <StatisticsNumber>2 031 737</StatisticsNumber>
            <StatisticsDescription>عدد العمليات الجراحية</StatisticsDescription>
          </StatisticCard>
          <StatisticCard>
            <Icon
              icon="e906"
              size={24}
              color={'white'}
              bkgColor={'redRose'}
              extendStyle={extendIcons}
            />
            <StatisticsNumber>21</StatisticsNumber>
            <StatisticsDescription>مقدمي الخدمة من القطاع الخاص المسجلين</StatisticsDescription>
          </StatisticCard>
          <StatisticCard>
            <Icon
              icon="e908"
              size={24}
              color={'white'}
              bkgColor={'darkBlue'}
              extendStyle={extendIcons}
            />
            <StatisticsNumber>2 702 439</StatisticsNumber>
            <StatisticsDescription>عدد المترددين على النظام</StatisticsDescription>
          </StatisticCard>
          <StatisticCard>
            <Icon
              icon="e909"
              size={24}
              color={'white'}
              bkgColor={'darkBlue'}
              extendStyle={extendIcons}
            />
            <StatisticsNumber>8</StatisticsNumber>
            <StatisticsDescription>المستشفيات التي تم تطويرها</StatisticsDescription>
          </StatisticCard>
          <StatisticCard>
            <Icon
              icon="e905"
              size={24}
              color={'white'}
              bkgColor={'lightBlue'}
              extendStyle={extendIcons}
            />
            <StatisticsNumber>31</StatisticsNumber>
            <StatisticsDescription>الوحدات و المراكز التي تم تطويرها</StatisticsDescription>
          </StatisticCard>
          <ColumnNote>عدد سكان المحافظة 1,382,000 مواطن</ColumnNote>
        </StatisticsWrapper>
        {!isExpanded && (
          <ExpandButtonMobile onClick={() => setIsExpanded(true)}>
            عرض إحصائيات
            <Icon icon="e92c" size="10" extendStyle={extendArrowIcon} />
          </ExpandButtonMobile>
        )}
      </Column>
      {!isExpanded && (
        <ExpandButton onClick={() => setIsExpanded(true)}>
          عرض إحصائيات
          <Icon icon="e92c" size="10" extendStyle={extendArrowIcon} />
        </ExpandButton>
      )}
    </Container>
  );
};

export default InfoGraph;
