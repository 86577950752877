import { ThemeProvider } from 'styled-components';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import theme from 'utils/theme';
import Layout from 'components/Layout';
import Home from 'pages/Home';
import Services from 'pages/Services';
import MediaCenter from 'pages/MediaCenter';
import AboutUs from 'pages/AboutUs';
import Career from 'pages/Career';
import ContactUs from 'pages/ContactUs';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Layout>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/services" component={Services} />
            <Route path="/Media" component={MediaCenter} />
            <Route exact path="/AboutUs" component={AboutUs} />
            <Route exact path="/Career" component={Career} />
            <Route exact path="/ContactUs" component={ContactUs} />
            <Redirect to="/" />
          </Switch>
        </Layout>
      </Router>
    </ThemeProvider>
  );
}

export default App;
