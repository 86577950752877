import Footer from 'components/Footer';
import Header from 'components/Header';
import React from 'react';
import LayoutContainer, { GlobalStyle } from './Layout.style';

const Layout = ({ children }) => (
  <LayoutContainer>
    <GlobalStyle />
    <Header />
    {children}
    <Footer />
  </LayoutContainer>
);

export default Layout;
