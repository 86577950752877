import styled from 'styled-components/macro';

export const Container = styled.div`
  display: flex;
  flex-direction: ${({ direction }) => direction || 'row'};
  justify-content: ${({ justifyContent }) => justifyContent || 'start'};
  align-items: ${({ alignItem }) => alignItem || 'start'};
  color: ${({ theme }) => theme.colors.darkBlue};
  width: ${({ width }) => width || 'min-content'};
  margin: ${({ margin }) => margin || '0'};
  ${({ extendStyle }) => extendStyle || ''}
`;

export const Image = styled.img`
  width: 288px;
  height: auto;
  margin-bottom: 16px;
  ${({ hasImage, theme }) => !hasImage && ` background-color: ${theme.colors.lightGrey};`}
`;

export const Title = styled.h6`
  ${({ theme }) => theme.textSize.h3}
  text-align: center;
`;

export const Date = styled.span`
  ${({ theme }) => theme.textSize.p3}
  color: ${({ theme }) => theme.colors.grey};
`;

export const Description = styled.p`
  ${({ theme }) => theme.textSize.p2}
  text-align: center;
`;

export const Link = styled.a`
  ${({ theme }) => theme.textSize.p2}
  color: ${({ theme }) => theme.colors.lightBlue};
`;
