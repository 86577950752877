import React from 'react';
import PropTypes from 'prop-types';
import { DrawerTopItemsConatiners, mobileLinksStyle } from './HeaderDrawerContent.style';
import Link from 'components/Link';

const DrawerItem = props => (
  <Link {...props} extendedStyle={mobileLinksStyle}>
    {props.text}
  </Link>
);

const HeaderDrawerContent = ({ headerNavigationButtons, onClose }) => (
  <DrawerTopItemsConatiners onClick={onClose}>
    {!headerNavigationButtons
      ? ''
      : headerNavigationButtons.map(navLink => <DrawerItem {...navLink} />)}
  </DrawerTopItemsConatiners>
);

HeaderDrawerContent.propTypes = {
  headerNavigationButtons: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  toggleDrawer: PropTypes.func.isRequired,
};

export default HeaderDrawerContent;
