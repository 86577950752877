import React from 'react';
import Icon from 'components/Icon';
import {
  Container,
  Step,
  StepNumber,
  TitleH3,
  StepTitle,
  StepDescription,
  hideOnMobile,
  hideOnDesktopAndTablet,
} from './PatientJourney.style';

const PatientJourney = () => {
  return (
    <Container>
      <Step withArrow isVertical pose="bottom" backgroundColor="turquoise" mobileOrder="2">
        <StepNumber>1</StepNumber>
        <StepTitle>اتصل بالمركز</StepTitle>
        <StepDescription>
          قم بالتسجيل في وحدة الرعاية الأولية التابعة لك وسيتم ربطك أنت و العائلة على طبيب الأسرة
        </StepDescription>
      </Step>
      <Step withArrow pose="right" backgroundColor="darkBlue" mobileOrder="1">
        <Icon
          isImageIcon
          size={100}
          src="https://uhia.vezeeta.com/assets/uhia/images/shield.png"
          extendStyle={hideOnMobile}
        />
        <Icon
          isImageIcon
          size={100}
          src="https://uhia.vezeeta.com/assets/uhia/images/shield-mobile.png"
          extendStyle={hideOnDesktopAndTablet}
        />
        <TitleH3>خطوات الحصول على الخدمة</TitleH3>
      </Step>
      <Step backgroundColor="orange" mobileOrder="6">
        <StepNumber>5</StepNumber>
        <StepTitle>اختار المستشفى</StepTitle>
        <StepDescription>
          وفي حالة إحتياج دخولك المستشفى لك الحق في إختيار المكان الذي ترغب العلاج به بشرط أن يكون
          متعاقد مع الهيئة
        </StepDescription>
      </Step>
      <Step withArrow pose="left" backgroundColor="yellow" mobileOrder="3">
        <StepNumber>2</StepNumber>
        <StepTitle>فحص طبيب الأسرة</StepTitle>
        <StepDescription>
          إتصل على 15344 لحجز موعد مع طبيب الأسرة في حالة الإحتياج للكشف
        </StepDescription>
      </Step>
      <Step withArrow pose="left" backgroundColor="redRose" mobileOrder="4">
        <StepNumber>3</StepNumber>
        <StepTitle>الفحص والعلاج المبدأي</StepTitle>
        <StepDescription>
          سيتم الكشف عليك من خلال طبيب الأسرة وسيقدم لك خدمة الرعاية الأولية بالإضافة إلى الأشعة
          والتحاليل والأدوية
        </StepDescription>
      </Step>
      <Step withArrow isVertical pose="top" backgroundColor="green" mobileOrder="5">
        <StepNumber>4</StepNumber>
        <StepTitle>فحص استشاري</StepTitle>
        <StepDescription>
          إذا إستدعت الحالة يتم تحويلك على أخصائي أو إستشاري لتشخيص حالتك وصرف العلاج المناسب
        </StepDescription>
      </Step>
    </Container>
  );
};

export default PatientJourney;
