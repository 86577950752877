import styled from 'styled-components';

export const FontIcon = styled.span`
  font-family: icomoon;
  margin: 0 5px;
  display: inline-flex;
  &:before {
    content: '\\${({ icon }) => icon || ''}';
    font-size: ${({ size }) => size}px;
    color: ${({ color }) => color};
    display: inline-block;
  }
  transform: ${({ flip, flipOnRtl, rotate, theme }) =>
    flip || (theme.direction === 'rtl' && flipOnRtl)
      ? 'scaleX(-1)'
      : rotate
      ? 'rotate(90deg)'
      : 'unset'};
  ${({ extendStyle }) => extendStyle || ''}
`;

export const ImageIcon = styled.img`
  margin: 0 5px;
  ${({ theme, flipOnRtl }) =>
    flipOnRtl &&
    theme.direction === 'rtl' &&
    `
    transform: scaleX(-1);
  `}
  height: auto;
  width: ${({ size }) => size || 16}px;
  color: ${({ color }) => color || ''};
  transform: ${({ rotate, theme }) =>
    rotate && theme.direction === 'ltr' ? 'rotate(90deg)' : 'unset'};
  display: inline-block;
  transform: ${({ flip }) => (flip && 'scaleX(-1)') || 'unset'};
  ${({ extendStyle }) => extendStyle || ''}
`;
