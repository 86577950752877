import { css } from 'styled-components/macro';

const sizes = {
  maxTablet: 1023,
  maxPhone: 767,
};

const colors = {
  red: '#B2314C',
  darkBlue: '#1D4A67',
  blue: '#2A79A9',
  lightBlue: '#2298C1',
  grey: '#CBCCCB',
  lightGrey: '#D4DFE5',
  backgroundGrey: '#F2F6F8',
  white: '#FFFFFF',
  turquoise: '#00C7CE',
  yellow: '#FDBA38',
  redRose: '#B2314D',
  green: '#79BC4B',
  orange: '#FF8637',
};

const textSize = {
  h1: css`
    font-family: AminMedium;
    font-size: 40px;
    line-height: 48px;
    font-weight: 400;
    margin: 0;
  `,
  h2: css`
    font-family: AminMedium;
    font-size: 32px;
    line-height: 40px;
    font-weight: 400;
    margin: 0;
  `,
  h3: css`
    font-family: AminMedium;
    font-size: 20px;
    line-height: 28px;
    font-weight: 400;
    margin: 0;
  `,
  p1: css`
    font-family: CoconNextArabic;
    font-size: 18px;
    line-height: 26px;
    font-weight: 400;
    margin: 0;
  `,
  p2: css`
    font-family: CoconNextArabic;
    font-size: 16px;
    line-height: 24px;
    margin: 0;
  `,
  p3: css`
    font-family: CoconNextArabic;
    font-size: 14px;
    line-height: 20px;
    margin: 0;
  `,
};

// create a media template
const media = {};
media.phone = (...args) => css`
  @media only screen and (max-width: ${sizes.maxPhone}px) {
    ${css(...args)}
  }
`;
media.tablet = (...args) => css`
  @media only screen and (min-width: ${sizes.maxPhone +
    1}px) and (max-width: ${sizes.maxTablet}px) {
    ${css(...args)}
  }
`;
media.desktop = (...args) => css`
  @media only screen and (min-width: ${sizes.maxTablet + 1}px) {
    ${css(...args)}
  }
`;

media.phoneAndTablet = (...args) => css`
  @media only screen and (max-width: ${sizes.maxTablet + 1}px) {
    ${css(...args)}
  }
`;

media.desktopAndTablet = (...args) => css`
  @media only screen and (min-width: ${sizes.maxPhone + 1}px) {
    ${css(...args)}
  }
`;

const layout = {
  row: css`
    max-width: 100%;
    width: 100%;
  `,
  containerDefault: css`
    margin: auto;
    display: block;
    ${media.phone`max-width: 100%;
    width: 100%;`}
    ${media.desktopAndTablet`max-width: 85%;
    width: 85%;`}
  `,
  // ToDO add (if needed) containerBig, containerSmall, ...etc after other pages design is finalized
};
const theme = {
  sizes,
  media,
  colors,
  layout,
  textSize,
  direction: 'rtl',
};

export default theme;
