import Icon from 'components/Icon';
import styled from 'styled-components/macro';

const FooterContainer = styled.div`
  background: ${({ theme }) => theme.colors.backgroundGrey};
  ${({ theme }) => theme.layout.row}
  color: ${({ theme }) => theme.colors.darkBlue};
`;
export const Content = styled.div`
  ${({ theme }) => theme.layout.containerDefault};
`;
export const FooterIcon = styled(Icon)`
  margin-right: 0px;
`;

export const ContentContainer = styled.div`
  padding: 32px 0px;
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrey};
  }
  &:last-child {
    justify-content: center;
  }
  ${({ theme }) => theme.media.phone`
    display: block;
    &:last-child {
      margin:0 auto;
      width: max-content;
    }
  `}
`;
export const IconText = styled.div`
  ${({ theme }) => theme.textSize.p1}
  width: 386.67px;
  text-align: ${({ align }) => align || ''};
  ${({ theme }) => theme.media.phone`
    margin: 0 auto;
    width: max-content;
    text-align: center;
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  `}
  & > a {
    text-decoration: underline;
    color: initial;
  }
`;
export default FooterContainer;
