import React from 'react';
import HomeCarousel from 'components/HomeCarousel';
import PatientJourney from 'components/PatientJourney';
import InfoGraph from 'components/InfoGraph';
import { DefinitionBlock, Title, Description, Row, DownloadLink } from './Home.style';

const Home = () => {
  return (
    <>
      <HomeCarousel />
      <DefinitionBlock>
        <Title color="white">الهيئة العامة للتأمين الصحي الشامل</Title>
        <Description color="white">
          الهيئة العامة للتأمين الصحي الشامل هي هيئة مستقلة أُنشئت بموجب القانون رقم (2) لسنة 2018
          ولائحته التنفيذية من أجل نظام التأمين الصحي الشامل. تنشأ الهيئة كهيئة اقتصادية وتكون لها
          شخصية اعتبارية وموازنة مستقلة، وتخضع للإشراف العام لرئيس مجلس الوزراء، ويكون مقرها الرئيسي
          بالقاهرة.
        </Description>
      </DefinitionBlock>
      <PatientJourney />
      <Title color="darkBlue">
        المحافظات المطبق بها
        <br /> نظام التأمين الصحي الشامل حاليا
      </Title>
      <InfoGraph />
      <Title color="darkBlue">القانون و اللائحة التنفيذية</Title>
      <Description color="darkBlue">
        قانون نظام التأمين الصحي الشامل و اللائحة التنفيذية
      </Description>
      <Row>
        <DownloadLink
          target="_blank"
          href="https://uhia.vezeeta.com/assets/uhia/pdf/execution-list-of-regulation.pdf"
        >
          اللائحة التنفيذية
        </DownloadLink>
        <DownloadLink
          target="_blank"
          href="https://uhia.vezeeta.com/assets/uhia/pdf/rule-number-2-for-2018.pdf"
        >
          القانون رقم 2 لسنة 2018
        </DownloadLink>
      </Row>
    </>
  );
};

export default Home;
