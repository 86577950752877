import React, { useState } from 'react';
import Card from 'components/Card';
import Icon from 'components/Icon';

import {
  Container,
  Title,
  Description,
  TitleWithMargin,
  List,
  ListSubTitle,
  ListItem,
  VisionAndMission,
  FlexItem,
  MembersWrapper,
  extendIcons,
  truncateStyle,
  extendHeader,
  extendCardStyle,
  extendFirstAndLastCardStyle,
} from './AboutUs.style';
import Truncate from 'components/Truncate';

export const AboutUs = () => {
  const [truncate, setTruncate] = useState({ specialties: true });
  const truncateHandle = section => {
    setTruncate({ ...truncate, [section]: false });
  };
  const minLength = {
    specialties: 3,
  };
  return (
    <Container>
      <Title>من نحن</Title>
      <Description>
        الهيئة العامة للتأمين الصحي الشامل هي هيئة مستقلة أنشئت بموجب القانون رقم 2 لسنة 2018 كهيئة
        اقتصادية وتكون لها شخصية اعتبارية وموازنة مستقلة و الذي ينص على أن التأمين الصحي الشامل نظام
        إلزامي علي جميع المواطنين ،ويغطى مظلته جميع المشتركين فيه، وتتحمل الدولة أعباء غير القادرين
        بناء على قرار يصدر من رئيس مجلس الوزراء.
      </Description>
      <Description>
        بجانب أخر تكون الهيئة مسئولة عن إدارة وتمويل نظام التأمين الصحي الشامل ومتابعة علاج المؤمن
        عليهم من جهات تقديم الخدمة الصحية التي يتيحها النظام الي ان يتم شفائهم وتستقر حالتهم الصحية.
      </Description>
      <Description>
        وأيضا تلتزم الهيئة بشراء الخدمات الصحية لأصحاب نظم التأمين والبرامج الصحية الخاصة، وتتولي
        جميع الأصول الإدارية والحقوق والالتزامات المالية الخاصة بالهيئة وفروعها والجهات التابعة
        لوزارة الصحة، ماعدا الأصول العلاجية وما يخص الجودة والاعتماد. وتكون الهيئة مسئولة عن اعتماد
        قوائم أسعار مجموعات الخدمات الطبية المقدمة، واعتماد استراتيجية استثمار أموال النظام طبقا
        للقواعد التي تحددها اللائحة التنفيذية لهذا القانون، واعتماد التقارير والحسابات المالية.
      </Description>
      <br />
      <Description>
        ويتم وضع قواعد الاستعانة بالخبرات المحلية والأجنبية لمعاونة الهيئة على القيام بعملها، وابداء
        الرأي في مشروعات القوانين واللوائح المتعلقة بنظام عمل الهيئة، وفي المعاهدات او الاتفاقيات او
        المواثيق الدولية ذات الصلة وتقوم أيضا بمراجعة وتقييم فاعلية إدارة وأداء برامج تطبيق النظام،
        واقتراح عقد الفروض اللازمة لتمويل البرامج والمشروعات التي تحقق اهداف الهيئة والنظام.
      </Description>
      <TitleWithMargin>ماذا نقدم</TitleWithMargin>
      <List>
        <Truncate
          type="byComponents"
          truncateHandle={() => truncateHandle('specialties')}
          truncate={truncate.specialties}
          minLength={minLength.specialties}
          extendStyle={truncateStyle}
        >
          <ListSubTitle>اختصاصات الهيئة العامة للتأمين الصحي الشامل</ListSubTitle>
          <ListItem>
            الهيئة مسئولة عن متابعة علاج المؤمن عليهم لدى أي من جهات تقديم الخدمة الصحية التي يتيحها
            النظام إلى أن يتم شفاؤهم أو تستقر حالتهم، أو يثبت عجزهم. وللمؤمن عليه الحق في اختيار
            جهات العلاج من بين الجهات المتعاقدة مع الهيئة لتقديم الخدمة طبقا لمستويات الإحالة
            المحددة في هذا القانون ولائحته التنفيذية.
          </ListItem>
          <ListItem>
            تلتزم الهيئة بشراء الخدمة الصحية لأصحاب نظم التأمين أو البرامج الصحية الخاصة سواء كانت
            الخدمة مقدمة بمستشفيات هيئة الرعاية أو بمستشفيات تلك النظم، وذلك طبقا للائحة الأسعار
            المطبقة بالهيئة.
          </ListItem>
          <ListItem>
            تتولى الهيئة تمويل خدمات النظام عن طريق التعاقد مع مقدمي خدمات الرعاية الصحية والأنظمة
            العلاجية التي تشملها هيئة الرعاية أو أي جهة أخرى تتعاقد مع الهيئة، وذلك وفقا لنظم
            التعاقد والأسعار والضوابط والإجراءات التي يقرها مجلس إدارة الهيئة ومعايير الجودة التي
            تحددها هيئة الإعتماد والرقابة، وذلك دون التقيد بأحكام قوانين المناقصات والمزايدات
            الحكومية المعمول بها.
          </ListItem>
          <ListItem>
            تصدر الهيئة شهادات العجز الناتج عن الإصابة بأحد الأمراض العضوية محددا بها نسبة العجز،
            كما تصدر شهادات العجز الناتج عن الإصابة بأحد الأمراض المهنية أو إصابات العمل الأخرى
            ونسبته.
          </ListItem>
          <ListItem>
            تلتزم الهيئة بإخطار المصاب بإنتهاء العلاج، وبالعجز الذي تخلف لديه (إن وجد) ونسبته،
            وللمصاب أو المريض أن يتظلم من تقرير إنتهاء العلاج أو تخلف العجز أمام لجان التحكيم الطبي
            المنصوص عليها بقوانين التأمينات الاجتماعية.{' '}
          </ListItem>
          <ListItem>
            تلتزم الهيئة بإخطار كل من صاحب العمل والهيئة القومية للتأمين الإجتماعي بذلك، مع بيان
            أيام التخلف عن العلاج (إن وجدت)، وذلك كله وفقا للشروط والأوضاع التي يصدر بها قرار من
            الوزير المختص بالصحة بالإتفاق مع الوزير المختص بالتأمينات الإجتماعية.
          </ListItem>
        </Truncate>
      </List>
      <TitleWithMargin>الرؤية والرسالة</TitleWithMargin>
      <VisionAndMission>
        <FlexItem bkgColor="darkBlue">
          <Icon
            icon="e90c"
            size={24}
            color={'darkBlue'}
            bkgColor={'white'}
            extendStyle={extendIcons}
          />
          <Title color="white" extendStyle={extendHeader}>
            الرؤية
          </Title>
          <Description color="white">
            أن يكون لنا تأثير في تحسين صحة الشعب المصري بتأمين العلاج المناسب بجودة عالمية وبشكل
            مستدام
          </Description>
        </FlexItem>
        <FlexItem bkgColor="redRose">
          <Icon
            icon="e90b"
            size={24}
            color={'darkBlue'}
            bkgColor={'white'}
            extendStyle={extendIcons}
          />
          <Title color="white" extendStyle={extendHeader}>
            الرسالة
          </Title>
          <Description color="white">
            أن تكون مصر رائدة عالميا في تقديم الرعاية الطبية التكافلية لمواطنيها وفقا للمعايير
            العالمية بشكل كفء ومستدام مدعومة بالإرادة الوطنية وكفاءة العاملين بها وتطبيق العلم
          </Description>
        </FlexItem>
      </VisionAndMission>
      <TitleWithMargin>الإدارة العليا</TitleWithMargin>
      <MembersWrapper>
        <Card
          src="https://uhia.vezeeta.com/assets/uhia/images/mohamed-moeet.png"
          title="الدكتور/ محمد معيط"
          desc="وزير المالية و رئيس مجلس إدارة الهيئة العامة للتأمين الصحي الشامل"
          direction="column"
          alignItem="center"
          extendStyle={extendFirstAndLastCardStyle}
        />
        <Card
          src="https://uhia.vezeeta.com/assets/uhia/images/ehab-aboeish.png"
          title="الدكتور/ إيهاب أبوعيش"
          desc="نائب وزير المالية للخزانة العامة ونائب رئيس مجلس إدارة الهيئة العامة للتأمين الصحي الشامل "
          direction="column"
          alignItem="center"
          extendStyle={extendCardStyle}
        />
        <Card
          src="https://uhia.vezeeta.com/assets/uhia/images/ahmed-elsobky.png"
          title="الدكتور/ أحمد السبكي"
          desc="رئيس مجلس إدارة الهيئة العامة للرعاية الصحية"
          direction="column"
          alignItem="center"
          extendStyle={extendCardStyle}
        />
        <Card
          src="https://uhia.vezeeta.com/assets/uhia/images/mohamed-dahy.png"
          title="الدكتور/ محمد ضاحي"
          desc="رئيس الهيئة العامة للتأمين الصحي "
          direction="column"
          alignItem="center"
          extendStyle={extendCardStyle}
        />
        <Card
          src="https://uhia.vezeeta.com/assets/uhia/images/sherif-samy.png"
          title="الأستاذ/ شريف سامي"
          desc="رئيس البنك التجارى و خبير الاستثمار و الأسواق المالية"
          direction="column"
          alignItem="center"
          extendStyle={extendCardStyle}
        />
        <Card
          src="https://uhia.vezeeta.com/assets/uhia/images/mohamed-elsweedy.png"
          title="المهندس/ محمد السويدي"
          desc="رئيس اتحاد الصناعات المصرية"
          direction="column"
          alignItem="center"
          extendStyle={extendCardStyle}
        />
        <Card
          src="https://uhia.vezeeta.com/assets/uhia/images/ebrahim-elaraby.png"
          title="الأستاذ/ إبراهيم العربي"
          desc="رئيس الإتحاد العام للغرف التجارية المصرية"
          direction="column"
          alignItem="center"
          extendStyle={extendCardStyle}
        />
        <Card
          src="https://uhia.vezeeta.com/assets/uhia/images/gebali-mohamed.png"
          title="الأستاذ/ جبالي محمد جبالي"
          desc="رئيس الاتحاد العام لنقابات العمال"
          direction="column"
          alignItem="center"
          extendStyle={extendCardStyle}
        />
        <Card
          src="https://uhia.vezeeta.com/assets/uhia/images/samy-abdelhady.png"
          title="الأستاذ/ سامي عبد الهادي"
          desc="نائب رئيس الهيئة القومية للتأمين الاجتماعي"
          direction="column"
          alignItem="center"
          extendStyle={extendCardStyle}
        />
        <Card
          src="https://uhia.vezeeta.com/assets/uhia/images/ehab-abolmagd.png"
          title="الدكتور/ إيهاب أبو المجد"
          desc="ممثل عن مقدمي الخدمة بالقطاع الخاص "
          direction="column"
          alignItem="center"
          extendStyle={extendCardStyle}
        />
        <Card
          title="الأستاذ/ على السيسي"
          src="https://uhia.vezeeta.com/assets/uhia/images/ali-elsisi.png"
          desc="رئيس قطاع الموازنة العامة وزارة المالية"
          direction="column"
          alignItem="center"
          extendStyle={extendCardStyle}
        />
        <Card
          title="الأستاذ/ حسن الرداد"
          src="https://uhia.vezeeta.com/assets/uhia/images/hassan-elraddad.png"
          desc="رئيس الإدارة المركزية لشئون مكتب وزير القوى العاملة "
          direction="column"
          alignItem="center"
          extendStyle={extendCardStyle}
        />
        <Card
          title="الدكتورة/ هبة نصار"
          src="https://uhia.vezeeta.com/assets/uhia/images/heba-nassar.png"
          desc="خبير في مجال اقتصاديات الصحة"
          direction="column"
          alignItem="center"
          extendStyle={extendCardStyle}
        />
        <Card
          src="https://uhia.vezeeta.com/assets/uhia/images/wael-abdelhady.png"
          title="الأستاذ/ وائل عبد الهادي"
          desc="خبير اكتواري متخصص في اكتواريات الصحة"
          direction="column"
          alignItem="center"
          margin="12px 1.5%"
          extendStyle={extendFirstAndLastCardStyle}
        />
      </MembersWrapper>
    </Container>
  );
};

export default AboutUs;
