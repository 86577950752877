import styled, { css, createGlobalStyle } from 'styled-components/macro';
import CoconNextArabicTtf from 'assets/fonts/cocon-next-arabic.ttf';
import AminMediumTtf from 'assets/fonts/amin-medium.ttf';
import IconMoon from 'assets/fonts/icomoon/icomoon.ttf';

const LayoutContainer = styled.div`
  width: 100%;
  height: 100vh;
  background: ${({ theme }) => theme.colors.white};
  direction: ${({ theme }) => theme.direction};
`;

const font = (name, url) => css`
  @font-face {
    font-family: ${name};
    src: url(${url}) format('truetype');
    font-style: normal;
    font-weight: normal;
  }
`;

export const GlobalStyle = createGlobalStyle`
  ${font('CoconNextArabic', CoconNextArabicTtf)}
  ${font('AminMedium', AminMediumTtf)}
  ${font('icomoon', IconMoon)}
  html {
    scroll-behavior: smooth;
  }
  body {
    margin:0;  
    background-color: ${({ theme }) => theme.colors.white};
    min-height: 100vh;
    ${({ theme }) => theme.textSize.p2}
    ${({ theme }) => theme.media.phone`${theme.textSize.p3}`}
    ${({ theme }) => theme.layout.row}
  }
  a {
    text-decoration: none;
  }
`;

export default LayoutContainer;
