import React from 'react';
import { BoxedContainer, ParagraphHeader, Description } from './BoxContainer.style';

const BoxContainer = ({ title, color, children, extendStyle }) => (
  <BoxedContainer extendStyle={extendStyle}>
    <ParagraphHeader color={color}>{title}</ParagraphHeader>
    <Description color={color}>{children}</Description>
  </BoxedContainer>
);

export default BoxContainer;
