import styled, { css } from 'styled-components/macro';

export const DrawerText = styled.span``;

export const DrawerBottomButton = styled.a`
  font-size: 16px;
  font-weight: 400;
  margin: 0 15px;
  float: ${props => (props.theme.direction === 'ltr' ? 'left' : 'right')};
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  border: 0;
  color: white;
  background: transparent;
  text-decoration: none;
  &:focus {
    outline-width: 0;
  }
`;
export const DrawerNavigation = styled.span`
  color: white !important;
  border: none;
  background: transparent;
  &:after {
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: '\\e905';
    margin-left: 20px;
    ${({ theme }) => (theme.direction === 'ltr' ? 'margin-right: 20px;' : '')};
    font-size: 20px;
  }
`;

export const TransparentA = styled.a`
  color: transparent;
`;

export const StyledListItemText = styled(DrawerText)`
  font-size: 16px !important;
  font-weight: 400 !important;
  float: right;
  cursor: pointer;
  &:after {
    ${props => props.theme.icon}
    ${props => props.theme.redLineIcon}
        content: ${props => (props.iconContent ? props.iconContent : 'unset')};
    margin-left: ${props => (props.theme.direction === 'ltr' ? '0' : '15px')};
    margin-right: ${props => (props.theme.direction === 'ltr' ? '15px' : '0')};
    font-size: 22px;
    box-shadow: 0px 11px 0px -8px red;
    display: inline-block;
    transform: ${props => (props.theme.direction === 'ltr' ? 'rotateY(180deg)' : 'unset')};
    -webkit-transform: ${props => (props.theme.direction === 'ltr' ? 'rotateY(180deg)' : 'unset')};
    -moz-transform: ${props => (props.theme.direction === 'ltr' ? 'rotateY(180deg)' : 'unset')};
    -o-transform: ${props => (props.theme.direction === 'ltr' ? 'rotateY(180deg)' : 'unset')};
    -ms-transform: ${props => (props.theme.direction === 'ltr' ? 'rotateY(180deg)' : 'unset')};
    color: white;
    ${({ rotateIconOnLTR, theme }) =>
      !(rotateIconOnLTR && theme.direction === 'ltr') ? '' : 'transform: scaleX(-1);'};
  }
`;

export const DrawerMenuBottomActionsContainer = styled.div`
  padding: 10px 0px 25px 15px;
  margin-top: auto;
  /* push to bottom */
  align-self: flex-start;
  direction: ${props => (props.theme.direction === 'ltr' ? 'rtl' : 'ltr')};
`;
export const DrawerTopItemsConatiners = styled.div``;
export const mobileLinksStyle = css`
  opacity: 1;
  display: block;
  padding: 0px 0px;
  height: 64px;
  align-items: center;
  text-align: right;
  ${({ theme, match }) => `& > a {
    color: ${match ? theme.colors.lightBlue : theme.colors.darkBlue};
    padding: 19px 16px;
  }
  box-shadow: inset 0px -1px 0px ${theme.colors.lightGrey};
  `}
`;
