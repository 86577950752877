import styled from 'styled-components/macro';

export const DefinitionBlock = styled.div`
  padding: 96px 25%;
  background-color: ${({ theme }) => theme.colors.darkBlue};
  ${({ theme }) => theme.media.phone`
    padding: 56px 29px;
  `};
`;

export const Title = styled.h2`
  ${({ theme }) => theme.textSize.h1}
  text-align: center;
  color: ${({ theme, color }) => theme.colors[color]};
  margin-bottom: 8px;
  ${({ theme }) => theme.media.phone`
    ${theme.textSize.h2}
    margin: 0 auto 8px;
    width: 90%;
  `};
`;

export const Description = styled.p`
  ${({ theme }) => theme.textSize.p1}
  text-align: center;
  color: ${({ theme, color }) => theme.colors[color]};
  ${({ theme }) => theme.media.phone`
    ${theme.textSize.p2}
  `};
`;

export const Row = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 24px auto 56px;
  ${({ theme }) => theme.media.phone`
    flex-direction: column-reverse;
  `};
`;

export const DownloadLink = styled.a`
  ${({ theme }) => theme.textSize.p1}
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.lightBlue};
  border-radius: 28px;
  box-sizing: border-box;
  width: 232px;
  max-height: 56px;
  padding: 15px 0;
  text-decoration: none;
  text-align: center;
  color: ${({ theme }) => theme.colors.lightBlue};
  margin: 0 8px;
  ${({ theme }) => theme.media.phone`
    max-width: 256px;
    width: 80%;
    margin: 6px 0;
  `};
`;
