import React from 'react';
import Icon from 'components/Icon';

import qrCode from 'assets/images/contactus-qr-code.png';
import {
  Container,
  Title,
  Description,
  SmallContainer,
  Row,
  SubTitle,
  QrCodeStyle,
} from './ContactUs.style';

export const ContactUs = () => {
  return (
    <Container>
      <Title>اتصل بنا</Title>
      <SmallContainer>
        <SubTitle>اتصل</SubTitle>
        <Description>
          <bdi>(+2)24178129 / (+2)24178125</bdi>
        </Description>
        <Description>info@uhia.gov.eg</Description>
      </SmallContainer>
      <SmallContainer>
        <SubTitle>فرع القاهرة</SubTitle>
        <Description> 24 شارع رشدي باشا, هليوبوليس </Description>
        <Row>
          <Icon isImageIcon size={'112'} src={qrCode} extendStyle={QrCodeStyle} />
        </Row>
      </SmallContainer>
      <SmallContainer>
        <SubTitle>فرع الاقصر</SubTitle>
        <Description>شارع على ابن ابى طالب متفرع من شارع التلفزيون</Description>
      </SmallContainer>
      <SmallContainer>
        <SubTitle>فرع بورسعيد</SubTitle>
        <Description>امتداد شارع كسري امام السكة الحديد, مجمع الهيئات</Description>
      </SmallContainer>
    </Container>
  );
};

export default ContactUs;
