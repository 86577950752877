import styled from 'styled-components/macro';

export const Title = styled.h1`
  ${({ theme }) => theme.textSize.h1}
  color: ${({ theme }) => theme.colors.white};
  position: absolute;
  right: 212px;
  max-width: ${({ maxWidth }) => maxWidth || '367px'};
  cursor: default;
  text-align: initial;
  text-shadow: 0px 0px 2px #000;
  ${({ theme }) => theme.media.phone`
    ${theme.textSize.h2}
    right: unset;
    text-align: center;
    max-width: 281px;
  `}
`;
