import styled, { css } from 'styled-components/macro';

export const extendCardStyle = css`
  margin: 0px 0px 80px 24px;
  & p {
    text-align: initial;
  }
  ${({ theme }) => theme.media.phone`
    margin: 0px 0px 40px;
    width: 328px;
    & img {
      width: 100%;
    }
    & a {
      width: 100%;
      margin: 0 auto;
    }
  `}
`;

export const Container = styled.div`
  display: flex;
  margin: 96px;
  // page height - header height - footer height - current margin
  min-height: calc(100vh - 96px - 177px - 192px);
  ${({ theme }) => theme.media.phone`
    margin: 16px;
    flex-direction: column;
  `}
`;

export const CardsWrapper = styled.div`
  display: flex;
  width: calc(100% - 288px);
  justify-content: normal;
  flex-wrap: wrap;
  padding: 0 12px;
  ${({ theme }) => theme.media.tablet`
    justify-content: space-around;
  `}
  ${({ theme }) => theme.media.phone`
    justify-content: space-around;
    width: 100%;
    padding: 16px 0px 12px;
  `}
`;

export const SideMenu = styled.div`
  width: 288px;
  ${({ theme }) => theme.media.phone`
    width: 100%;
  `}
  display: flex;
  ${({ theme }) => theme.media.desktopAndTablet`
    flex-wrap: wrap;
    height: fit-content;
  `};
`;
export const LinkStyle = css`
  opacity: 1;
  width: 100%;
  display: block;
  ${({ theme, match }) => theme.media.desktopAndTablet`
    height: 26px;  
    padding: 15px 16px;
    margin-bottom: 1px;
    ${match && `box-shadow: inset -4px 0px 0px ${theme.colors.lightBlue};`}
  `}
  ${({ theme, match }) => theme.media.phone`
    height: 26px;  
    padding: 7px;
    &:not(:last-child) {
      margin-left: 4px;
    }
    text-align: center;
    ${match && `box-shadow: inset 0px -2px 0px ${theme.colors.lightBlue};`}
  `}
  ${({ theme, match }) => `
    background: ${theme.colors.backgroundGrey};
    & > a {
      color: ${match ? theme.colors.lightBlue : theme.colors.darkBlue};
    }
  `}
`;
