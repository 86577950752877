import React from 'react';

import TruncateContainer, { Container, TruncatedSign } from './Truncate.style';

export const Truncate = ({ type, truncate, truncateHandle, minLength, extendStyle, children }) => {
  let heightHandle = type === 'byHeight' && truncate ? minLength : 'initial';
  console.log(truncate);
  let childrenHandle =
    type === 'byComponents' && truncate ? children.slice(0, minLength) : children;
  return (
    <TruncateContainer extendStyle={extendStyle} onClick={truncateHandle}>
      <Container height={heightHandle} truncate={truncate}>
        {childrenHandle}
      </Container>
      {truncate && <TruncatedSign type={type}>شاهد المزيد</TruncatedSign>}
    </TruncateContainer>
  );
};

export default Truncate;
