import styled, { css } from 'styled-components/macro';

export const Container = styled.div`
  margin: 96px 108px 96px 108px;
  // page height - header height - footer height - current margin
  min-height: calc(100vh - 96px - 177px - 192px);
  ${({ theme }) => theme.media.phone`margin: 56px 40px;`}
`;
export const Row = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 16px 0px 4px 0px;
  width: 100%;
`;
export const CallUs = styled.div`
  margin-bottom: 56px;
`;
export const Title = styled.h2`
  ${({ theme }) => theme.textSize.h1}
  ${({ theme }) => theme.media.phone`${({ theme }) => theme.textSize.h2}
  margin-bottom: 32px;`}
  text-align: center;
  color: ${({ theme, color }) => theme.colors[color] || theme.colors.darkBlue};
  margin-bottom: 24px;
`;

export const Description = styled.p`
  ${({ theme }) => theme.textSize.p1}
  text-align: center;
  color: ${({ theme, color }) => theme.colors[color] || theme.colors.darkBlue};
  margin: ${({ margin }) => margin || ''};
`;
export const Divider = styled.hr`
  margin: 24px auto;
  width: 178px;
  height: 1px;
  border: 0px;
  background: ${({ theme }) => theme.colors.lightGrey};
  &:first-child {
    margin-top: 0px;
  }
`;

export const SmallContainer = styled.div`
  margin: 0 auto;
  margin-bottom: 40px;
`;

export const SubTitle = styled.h4`
  ${({ theme }) => theme.textSize.h3};
  color: ${({ theme }) => theme.colors.darkBlue};
  text-align: center;
  margin-bottom: 8px;
`;

export const SubTitleAddresses = styled(SubTitle)`
  margin-bottom: 24px;
`;

export const SmallDescription = styled.li`
  ${({ theme }) => theme.textSize.p1}
`;

export const QrCodeStyle = css`
  padding: 16px;
  ${({ theme }) => `border: 4px solid ${theme.colors.backgroundGrey}`};
  ${({ theme }) => theme.media.phone`display: none;`}
`;
