import React from 'react';
import { withTheme } from 'styled-components';
import PoweredBy from 'components/PoweredBy';
import FooterContainer, { Content, ContentContainer, FooterIcon, IconText } from './Footer.style';

const Footer = ({ theme }) => (
  <FooterContainer>
    <Content>
      <ContentContainer>
        <IconText align={'left'}>
          <FooterIcon icon="e902" size={16} color={theme.colors.red} />
          <a href="mailto:info@uhia.gov.eg">info@uhia.gov.eg</a>
        </IconText>
        <IconText align={'center'}>
          <FooterIcon icon="e903" size={16} color={theme.colors.red} />

          <bdi>(+2)24178129 / (+2)24178125</bdi>
        </IconText>
        <IconText>
          <FooterIcon icon="e904" size={16} color={theme.colors.red} />
          24 شارع رشدي باشا، هليوبوليس
        </IconText>
      </ContentContainer>
      <ContentContainer>
        <PoweredBy />
      </ContentContainer>
    </Content>
  </FooterContainer>
);

export default withTheme(Footer);
