import React from 'react';
import Vezeeta from 'assets/images/vezeetaLogo.png';
import { Container, Slogan, Logo } from './PoweredBy.style';

const PoweredBy = () => (
  <Container>
    <Slogan>برعاية و تطوير</Slogan>
    <Logo src={Vezeeta} />
  </Container>
);

export default PoweredBy;
