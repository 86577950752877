import React, { useState } from 'react';
import HeaderContainer, { extendMenuIcon, MainLogo, MainLogoContainer, Menu } from './Header.style';
import Logo from 'assets/images/logo.png';
import Link from 'components/Link';
import Icon from 'components/Icon';
import Drawer from 'components/Drawer';
import HeaderDrawerContent from 'components/HeaderDrawerContent';

const Header = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };
  const navLinks = [
    {
      text: 'الرئيسية',
      to: '/',
      key: 'main',
      activeOnlyWhenExact: true,
    },
    {
      text: 'عن الهيئة',
      to: '/AboutUs',
      key: 'aboutus',
      activeOnlyWhenExact: true,
    },
    {
      text: 'الخدمات المقدمة',
      to: '/Services',
      key: 'services',
      activeOnlyWhenExact: true,
    },
    {
      text: 'المركز الإعلامي',
      to: '/Media',
      key: 'media',
      activeOnlyWhenExact: false,
    },
    {
      text: 'الوظائف',
      to: '/Career',
      key: 'career',
      activeOnlyWhenExact: true,
    },
    {
      text: 'اتصل بنا',
      to: '/ContactUs',
      key: 'contactUs',
      activeOnlyWhenExact: true,
    },
  ];
  return (
    <HeaderContainer>
      <MainLogoContainer>
        <MainLogo src={Logo} />
      </MainLogoContainer>
      <Menu>
        {navLinks.map(link => (
          <Link {...link}>{link.text}</Link>
        ))}
      </Menu>
      {isDrawerOpen ? (
        <Icon icon="e90d" onClick={toggleDrawer} size={19.999} extendStyle={extendMenuIcon} />
      ) : (
        <Icon icon="e90e" onClick={toggleDrawer} size={19.999} extendStyle={extendMenuIcon} />
      )}
      <Drawer open={isDrawerOpen} onClose={() => toggleDrawer(false)}>
        <HeaderDrawerContent
          headerNavigationButtons={navLinks}
          onClose={() => toggleDrawer(false)}
          toggleDrawer={toggleDrawer}
        />
      </Drawer>
    </HeaderContainer>
  );
};

export default Header;
