import styled, { css } from 'styled-components/macro';

export const marginTopStyle = css`
  margin-top: 40px;
  ${({ theme }) => theme.media.phone`
    margin-top: 24px;
  `}
`;

export const Title = styled.h2`
  ${({ theme, size }) => theme.textSize[size || 'h1']}
  text-align: center;
  color: ${({ theme, color }) => theme.colors[color]};
  margin-bottom: 33px;
  ${({ theme }) => theme.media.phone`
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 16px;
  `}
`;

export const Description = styled.p`
  ${({ theme }) => theme.textSize.p1}
  text-align: center;
  color: ${({ theme, color }) => theme.colors[color]};
  ${({ margined }) => (margined ? 'margin-bottom: 33px' : '')}
`;

export const ServicesContainer = styled.div`
  /* margin: 40px 334px 96px; */
  margin: 96px 20%;
  ${({ theme }) => theme.media.phone`
      margin: 56px 5%;
  `}
`;

export const StepNumberContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 96px;
  ${({ theme }) => theme.media.phone`
    margin-top: 56px;
  `}
`;

export const StepNumber = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-clip: padding-box;
  background-color: ${({ theme }) => theme.colors.lightBlue};
  border: 8px solid rgba(34, 152, 193, 0.1);
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.04);
  border-radius: 50%;
  width: 64px;
  height: 64px;
  ${({ theme }) => theme.media.phone`
  width: 56px;
  height: 56px;`};
  font-family: CoconNextArabic;
  font-size: 32px;
  color: ${({ theme }) => theme.colors.white};
  margin-bottom: 16px;
`;

export const ParagraphHeader = styled.h3`
  ${({ theme }) => theme.textSize.h3}
  text-align: right;
  color: ${({ theme, color }) => theme.colors[color]};
  margin-top: 56px;
  margin-bottom: 16px;
`;

export const StepsDescription = styled.p`
  margin: 0px auto;
  text-align: right;
  color: ${({ theme, color }) => theme.colors[color]};
`;

export const truncateStyle = css`
  ${({ theme }) => theme.media.desktopAndTablet`
    & > div {
      height: initial;
    }
    & > span {
      display: none;
    }
  `}
`;

export const TableHeaderRow = styled.tr`
  background-color: ${({ theme }) => theme.colors.darkBlue};
`;

export const TableHeader = styled.th`
  ${({ theme }) => theme.textSize.p1};
  color: ${({ theme }) => theme.colors.white};
  padding: 16px 24px;
  text-align: right;
  border: 1px solid ${({ theme }) => theme.colors.grey};
`;
export const TableRow = styled.tr`
  &:nth-child(odd) {
    background-color: ${({ theme }) => theme.colors.backgroundGrey};
  }
  border: 1px solid ${({ theme }) => theme.colors.backgroundGrey};
  padding: 0 20px;
`;
export const Table = styled.table`
  border-spacing: 0;
  width: 100%;
`;
export const TableColomn = styled.td`
  padding: 16px 24px;
  border: 1px solid ${({ theme }) => theme.colors.lightGrey};
  color: ${({ theme }) => theme.colors.darkBlue};
`;

export const OrderedList = styled.ol`
  color: ${({ theme }) => theme.colors.darkBlue};
  margin: 0px;
  padding-right: 16px;
  & > li {
    padding-right: 10px;
  }
  ${({ theme }) => theme.media.phone`
      padding: 0 18px;
  `};
`;

export const OrderedListExtended = styled(OrderedList)`
  margin-bottom: 16px;
`;

export const UnorderedList = styled.ul`
  color: ${({ theme }) => theme.colors.darkBlue};
  padding-right: 16px;
  margin: 0px;
  & > li {
    padding-right: 10px;
  }
  ${({ theme }) => theme.media.phone`
      padding: 0 18px;
  `}
`;
