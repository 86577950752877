import styled, { css } from 'styled-components/macro';

const HeaderContainer = styled.div`
  height: 64px;
  ${({ theme }) => theme.media.desktop`height:96px`};
  background: ${({ theme }) => theme.colors.darkBlue};
  ${({ theme }) => theme.layout.row}
`;

export const extendMenuIcon = css`
  float: left;
  margin: 20px 16px;
  color: ${({ theme }) => theme.colors.white};
  ${({ theme }) => theme.media.desktop`display: none;`}
`;

export const MainLogoContainer = styled.div`
  display: inline-block;
  width: 87.6px;
  height: inherit;
  border-left: 8px solid ${({ theme }) => theme.colors.lightBlue};
  ${({ theme }) => theme.media.desktop`
    border-left-width: 30px;
    width: 234px;
  `}
  background: ${({ theme }) => theme.colors.white};
`;

export const Menu = styled.div`
  margin-right: 141px;
  height: 96px;
  vertical-align: top;
  display: none;
  ${({ theme }) => theme.media.tablet`margin-right: 20px;`}
  ${({ theme }) => theme.media.desktop`display: inline-block;`}
`;
export const MainLogo = styled.img`
  width: 63.6px;
  padding: 8px 16px 8px 8px;
  ${({ theme }) => theme.media.desktop`padding: 8px 64px;
  width: 106px;
  height: 80px;`}
  box-shadow: inset 0px -1px 0px rgba(29, 74, 103, 0.08);
`;

export default HeaderContainer;
