import styled, { createGlobalStyle } from 'styled-components/macro';

export const PreventBodyScrolling = createGlobalStyle`
  body {
    ${({ isDrawerOpen }) => isDrawerOpen && 'overflow: hidden;'}
  }
`;

export const DrawerContent = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  top: 64px;
  left: 0;
  z-index: 11;
  color: white !important;
  background: ${({ theme }) => theme.colors.white};
  transition: 0.1s;
  display: ${props => (props.open ? 'grid' : 'none')};
  padding-bottom: 20%;

  &.side-slide-enter {
    transform: translate(-100%, 0);
  }

  &.side-slide-enter.side-slide-enter-active {
    transform: translate(0, 0);
    transition: transform 200ms linear;
  }

  &.side-slide-leave {
    transform: translate(0, 0);
  }

  &.side-slide-leave.side-slide-leave-active {
    transform: translate(-100%, 0);
    transition: transform 200ms linear;
  }
`;
