import styled from 'styled-components/macro';

export const BoxedContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0 40px;
  &:not(:last-of-type) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrey};
  }
  &:not(:last-child) {
    padding-bottom: 40px;
  }
  ${({ theme }) => theme.media.phone`
    padding: 0 24px;
    &:not(:last-child){
      padding-bottom: 24px;
    }
  `}
  ${({ theme }) => theme.media.phone`
    
  `}
  ${({ extendStyle }) => extendStyle || ''}
`;

export const ParagraphHeader = styled.h3`
  ${({ theme }) => theme.textSize.h3}
  color: ${({ theme, color }) => theme.colors[color]};
`;

export const Description = styled.p`
  ${({ theme }) => theme.textSize.p1}
  color: ${({ theme, color }) => theme.colors[color]};
`;
