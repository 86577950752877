import styled from 'styled-components/macro';

const LinkContainer = styled.li`
  display: inline-block;
  list-style-type: none;
  & > a {
    color: ${({ theme }) => theme.colors.white};
    display: block;
  }
  padding: 35px 16px;
  opacity: 0.5;
  ${({ theme }) => theme.textSize.p1}
  ${({ match }) => match && 'opacity: 1;'}
  ${({ theme }) => theme.media.tablet`padding: 35px 10px;`};
  ${({ extendedStyle }) => extendedStyle || ''}
`;

export default LinkContainer;
