import React from 'react';
import { Container, Row, Title, Description, CareerLink, DownloadLink } from './Career.style';

export const Career = () => {
  return (
    <Container>
      <Title>طلب توظيف</Title>
      <Description>
        قم بتحميل النموذج لملء البيانات وارسله عبر البريد الالكتروني إلى العنوان{' '}
        <CareerLink href={'mailto:career@uhia.gov.eg'}>career@uhia.gov.eg</CareerLink>
      </Description>
      <Row>
        <DownloadLink
          target="_blank"
          download
          href="https://uhia.vezeeta.com/assets/uhia/docs/apply-for-a-job.doc"
        >
          طلب التوظيف
        </DownloadLink>
      </Row>
    </Container>
  );
};

export default Career;
