import React from 'react';
import { Link as ReactLink, useRouteMatch } from 'react-router-dom';
import LinkContainer from './Link.style';

const Link = ({ children, to, extendedStyle, activeOnlyWhenExact }) => {
  const match = useRouteMatch({
    path: to,
    exact: activeOnlyWhenExact,
  });
  return (
    <LinkContainer extendedStyle={extendedStyle} match={match}>
      <ReactLink to={to}>{children}</ReactLink>
    </LinkContainer>
  );
};

export default Link;
