import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import Card from 'components/Card';
import Link from 'components/Link';
import { Container, CardsWrapper, SideMenu, extendCardStyle, LinkStyle } from './MediaCenter.style';

const MediaCenter = () => {
  let { path, url } = useRouteMatch();
  const news = [
    {
      src: 'https://uhia.vezeeta.com/assets/uhia/images/news-1.png',
      id: 1,
      date: '08.13.2021',
      desc: 'وزير المالية رئيس الهيئة العامة للتأمين الصحى الشامل: تكليف رئاسى باستدامة التدفقات المالية لنظام التأمين الصحى الشامل مد مظلة الرعاية الصحية المتكاملة لكل أفراد الأسرة المصرية...',
      direction: 'column',
    },
    {
      src: 'https://uhia.vezeeta.com/assets/uhia/images/news-2.png',
      id: 2,
      date: '08.13.2021',
      desc: 'وزير المالية رئيس الهيئة العامة للتأمين الصحى الشامل: تكليف رئاسى بمد مظلة التأمين الصحى الشامل لكل المصريين خلال ١٠ سنوات...',
      direction: 'column',
    },
    {
      src: 'https://uhia.vezeeta.com/assets/uhia/images/news-3.png',
      id: 3,
      date: '08.13.2021',
      desc: 'استكمالا للجولة الميدانية قام بالأمس الاستاذ حسام صادق المدير التنفيذي لهيئة التأمين الصحي الشامل بجولة تفقدية بمستشفي الكرنك الدولي (مستشفى الأقصر العام سابقاً).',
      direction: 'column',
    },
    {
      src: 'https://uhia.vezeeta.com/assets/uhia/images/news-4.png',
      id: 4,
      date: '08.13.2021',
      desc: 'لقاء بين الاستاذ حسام صادق المدير التنفيذي لهيئة التأمين الصحي الشامل برؤساء هيئات: التأمين الصحي الشامل والرعاية الصحية والاعتماد والرقابة الصحية فرع الأقصر.',
      direction: 'column',
    },
    {
      src: 'https://uhia.vezeeta.com/assets/uhia/images/news-5.png',
      id: 5,
      date: '08.13.2021',
      desc: 'المهندس حسام صادق المدير التنفيذي للهيئة العامة للتأمين الصحي الشامل الامس السبت بجولة ميدانية بمحافظة الأقصر رافقه خلالها عدد من مديري الإدارات والأقسام المختلفة بالهيئة وفرع الأقصر.',
      direction: 'column',
    },
    {
      src: 'https://uhia.vezeeta.com/assets/uhia/images/news-6.png',
      id: 6,
      date: '08.13.2021',
      desc: 'لقاء بين بعثة المنظمة الدولية للهجرة في مصر مع الهيئة العامة للتأمين الصحي الشامل والهيئة العامة للرعاية الصحية لمناقشة إدراج المهاجرين في برنامج التأمين الصحي الشامل الجديد',
      direction: 'column',
    },
    {
      src: 'https://uhia.vezeeta.com/assets/uhia/images/news-7.png',
      id: 7,
      date: '08.13.2021',
      desc: 'المهندس حسام صادق المدير التنفيذي للهيئة العامة للتأمين الصحي الشامل وسيادة اللواء أشرف أبو عيش مدير عام الإدارة الاستراتيجية بالهيئة بحضور اجتماع مع الدكتور سامر الرفاعي رئيس الجمعية العربية لتطوير الصيادلة ومعالي الدكتور هشام ستيت نائب رئيس هيئة الشراء الموحد',
      direction: 'column',
    },
    {
      src: 'https://uhia.vezeeta.com/assets/uhia/images/news-8.png',
      id: 8,
      date: '08.13.2021',
      desc: 'مجمع الإصدارات المؤمنة والذكية يوقع بروتوكول تعاون مع وزارة المالية متمثلة في الهيئة العامة للتأمين الصحي الشامل ومصلحة الضرائب المصرية',
      direction: 'column',
    },
  ];
  const videos = [
    {
      src: 'https://uhia.vezeeta.com/assets/uhia/images/video-1.png',
      id: 1,
      date: '08.13.2021',
      desc: 'وزير المالية يتحدث ل ايكونومي بلس عن آخر المستجدات حول منظومة التأمين الصحي الشامل ويكشف عن صفقات شراء لقاحات كورونا',
      link: 'https://youtu.be/e4gHJI-8w9Q',
      linkText: 'شاهد هنا',
      direction: 'column',
    },
    {
      src: 'https://uhia.vezeeta.com/assets/uhia/images/video-2.png',
      id: 2,
      date: '08.13.2021',
      desc: 'الحوار التلفزيونيc مع الاستاذ حسام صادق المدير التنفيذي للهيئة العامة للتأمين الصحي الشامل ببرنامج الحياة اليوم',
      link: 'https://youtu.be/SV9R_TTubdI',
      linkText: 'شاهد هنا',
      direction: 'column',
    },
    {
      src: 'https://uhia.vezeeta.com/assets/uhia/images/video-3.png',
      id: 3,
      date: '08.13.2021',
      desc: 'الأستاذ حسام صادق المدير التنفيذي للهيئة العامة للتأمين الصحي الشامل اثناء لقائه مع قناة طيبة',
      link: 'https://youtu.be/UYHdm3grMlg',
      linkText: 'شاهد هنا',
      direction: 'column',
    },
  ];
  const cards = type => (
    <CardsWrapper>
      {type.map(_card => (
        <Card key={_card.id} {..._card} extendStyle={extendCardStyle} />
      ))}
    </CardsWrapper>
  );
  return (
    <Container>
      <SideMenu>
        <Link to={`${url}/news`} extendedStyle={LinkStyle}>
          الأخبار
        </Link>
        <Link to={`${url}/videos`} extendedStyle={LinkStyle}>
          الفيديوهات
        </Link>
      </SideMenu>
      <Switch>
        <Route exact path={`${path}/news`}>
          {cards(news)}
        </Route>
        <Route exact path={`${path}/videos`}>
          {cards(videos)}
        </Route>
        <Redirect to={`${path}/news`}>{cards(news)}</Redirect>
      </Switch>
    </Container>
  );
};

export default MediaCenter;
