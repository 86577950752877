import styled, { css } from 'styled-components/macro';

export const extendImage = css`
  width: 256px;
  margin: 0 auto;
`;

export const extendIcons = css`
  background: ${({ bkgColor, theme }) => theme.colors[bkgColor]};
  border: 8px solid ${({ bkgColor, theme }) => theme.colors[bkgColor]}1A; // for opacity
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.04);
  border-radius: 50%;
  width: 64px;
  height: 64px;
  background-clip: padding-box;
  align-items: center;
  justify-content: center;
  margin: 0 0 16px;
`;

export const extendArrowIcon = css`
  margin: 0 11px;
`;

export const Container = styled.div`
  display: flex;
  width: 85%;
  margin: 32px auto 96px;
  box-sizing: border-box;
  flex-wrap: wrap;
  ${({ theme }) => theme.media.phone`
    flex-direction: column-reverse;
    align-items: center;
    margin: 0 0 56px;
    width: 100%;
  `}
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  box-sizing: border-box;
  padding: 56px 0;
  border: 1px solid ${({ theme }) => theme.colors.lightGrey};
  ${({ theme }) => theme.media.phone`
    width: 100%;
    border: 0;
    padding: 40px 0;
    border-bottom: 1px solid ${theme.colors.lightGrey};
  `}
`;

export const ExpandButton = styled.div`
  ${({ theme }) => theme.textSize.p1}
  flex: 1;
  text-align: center;
  padding: 19px 0;
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.darkBlue};
  color: ${({ theme }) => theme.colors.white};
  ${({ theme }) => theme.media.phone`
    display: none;
  `}
`;

export const ExpandButtonMobile = styled.div`
  ${({ theme }) => theme.media.desktop`
    display: none;
  `}
  ${({ theme }) => theme.media.tablet`
    display: none;
  `}
  ${({ theme }) => theme.textSize.p1}
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 28px;
  box-sizing: border-box;
  width: 80%;
  max-width: 256px;
  max-height: 56px;
  padding: 15px 0;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.lightBlue};
  margin: 16px 0;
`;

export const StatisticsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  height: ${({ isExpanded }) => (isExpanded ? 'auto' : '0')};
  overflow: hidden;
  /* transition: height 1s ease-in-out; */
  ${({ theme }) => theme.media.phone`
    width: 100%;
  `}
`;

export const StatisticCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  box-sizing: border-box;
  color: ${({ theme }) => theme.colors.darkBlue};
  margin: 28px 0;
`;

export const StatisticsNumber = styled.span`
  font-family: CoconNextArabic;
  font-size: 32px;
  line-height: 32px;
  direction: ltr;
  ${({ theme }) => theme.media.phone`
    width: 100%;
    text-align: center;
  `}
`;

export const StatisticsDescription = styled.span`
  ${({ theme }) => theme.textSize.p2}
  text-align: center;
  width: 60%;
  ${({ theme }) => theme.media.phone`
    width: 80%;
  `}
`;

export const ColumnNote = styled.span`
  ${({ theme }) => theme.textSize.p1}
  text-align: center;
  color: ${({ theme }) => theme.colors.darkBlue};
  margin: 28px auto 0;
`;
