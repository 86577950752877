import React, { useState } from 'react';
import BoxContainer from 'components/BoxContainer';
import Truncate from 'components/Truncate';
import {
  ServicesContainer,
  Title,
  Description,
  StepNumber,
  StepsDescription,
  ParagraphHeader,
  StepNumberContainer,
  TableHeaderRow,
  TableHeader,
  TableRow,
  Table,
  TableColomn,
  marginTopStyle,
  OrderedList,
  UnorderedList,
  truncateStyle,
  OrderedListExtended,
} from './Services.style';
import BoxSection from 'components/BoxSection';

const Services = () => {
  const [truncate, setTruncate] = useState({ requirements: true, strategy: true, central: true });
  const truncateHandle = section => {
    setTruncate({ ...truncate, [section]: false });
  };
  const minLength = {
    requirements: '385px',
    strategy: '258px',
    central: '204px',
  };
  return (
    <ServicesContainer>
      <StepNumberContainer>
        <StepNumber>1</StepNumber>
      </StepNumberContainer>
      <Title size="h2" color="darkBlue">
        إدارة المطالبات بالهيئة العامة للتأمين الصحي الشامل
      </Title>
      <BoxSection>
        <Truncate
          type="byHeight"
          truncateHandle={() => truncateHandle('requirements')}
          truncate={truncate.requirements}
          minLength={minLength.requirements}
          extendStyle={truncateStyle}
        >
          <BoxContainer extendStyle={marginTopStyle} color="darkBlue" title="الإدارة المركزية">
            تأسست الإدارة المركزية للمطالبات في يناير 2020 لوضع أسس وضوابط استلام ومراجعة المطالبات
            في فروع الهيئة. وتم التخطيط لها لتكون مركزا لإدارة ومتابعة الإدارات الفرعية للمطالبات في
            جميع محافظات الجمهورية تباعا بحلول 2030. ويتم مراجعة المطالبات على ثلاثة مراحل: مراجعة
            إدارية وفنية (طبية) وحسابية. وتم استقطاب عدد من الخبرات من شركات عالمية متطورة رائدة في
            المجال الطبي والتأميني للعمل في الإدارة ومتابعة المطالبات في المحافظات المختلفة بدءا
            ببورسعيد.
          </BoxContainer>
          <BoxContainer extendStyle={marginTopStyle} color="darkBlue" title="نظام التحول الرقمي">
            تهدف الهيئة لبدأ العمل بالنظام الالكتروني في أقرب وقت، يتم متابعة هذا التحول عن طريق
            التنسيق مع الإدارة المركزية لتكنولوجيا المعلومات بالهيئة.
            <br />
            ويتم العمل بالوقت الحالي عن طريق بعض الأدوات الرقمية لتسهيل عملية المراجعة وسرعة دفع
            المطالبات حتى يتم انطلاق النظام الإلكتروني.
          </BoxContainer>
          <BoxContainer extendStyle={marginTopStyle} color="darkBlue" title="إدارة المطالبات">
            في فبراير 2020 تم إعادة تقسيم إدارة المطالبات كأول نموذج لإدارة المطالبات الحديثة
            بالهيئة العامة للتامين الصحي الشامل وتم تقسيم وحدات داخل الإدارة مثل وحده استلام
            المطالبات والمراجعة الإدارية والفنية والحسابية. تم عمل برامج تدريبيه مكثفه وورش عمل مع
            السادة موظفي الهيئة العامة للتأمين الصحي المنقولين الي الهيئة العامة للتامين الصحي
            الشامل. وقامت إدارة المطالبات بعمل عدة اجتماعات مع مسؤولي المطالبات في مستشفيات هيئة
            الرعاية ووضع أسس لتسليم المطالبات مثل إضافة ملف رقمي لضبط طريقة التسليم ومتابعه الاستلام
            بدقه. وبدأنا بالفعل في استلام المطالبات في فبراير 2020 من مقدمي الخدمة الطبية التابعين
            لهيئة الرعاية الصحية، ثم بدأ استلام مطالبات مقدمي الخدمة من القطاع الخاص الذين يتم
            التعاقد معهم تباعا وذلك عن الخدمات الطبية التي قدمت ابتداء من أكتوبر حتى الان
          </BoxContainer>
          <BoxContainer extendStyle={marginTopStyle} color="darkBlue" title="الفروع">
            <UnorderedList>
              <li>يتمتع حاليا جميع مواطني محافظة بورسعيد بخدمات الهيئة.</li>
              <li>تم بدء التشغيل الفعلي للمنظومة فى محافظة الأقصر في يوليو 2021.</li>
              <li>قريبا سيتم فتح فرع الإسماعيلية.</li>
            </UnorderedList>
          </BoxContainer>
        </Truncate>
      </BoxSection>
      <StepNumberContainer>
        <StepNumber>2</StepNumber>
      </StepNumberContainer>
      <Title size="h2" color="darkBlue">
        الإدارة الاستراتيجية الهيئة العامة للتأمين الصحي الشامل
      </Title>
      <BoxSection>
        <Truncate
          type="byHeight"
          truncateHandle={() => truncateHandle('strategy')}
          truncate={truncate.strategy}
          minLength={minLength.strategy}
          extendStyle={truncateStyle}
        >
          <BoxContainer
            extendStyle={marginTopStyle}
            color="darkBlue"
            title="المهام و الصلاحيات الإدارة العامةالاستراتيجية"
          >
            <OrderedList>
              <li>
                وضع ومتابعة تنفيذ كافة الأنشطة والمهام المتعلقة بتطوير وتقيم استراتيجية الهيئة.
              </li>
              <li>
                مراجعة وتقيم الأداء المؤسسي بكافة الادارات وفق معاير المؤشرات الأداء المعتمدة.
              </li>
              <li>
                وضع منهجية محددة لرسم الخطط الاستراتيجية والتشغيلية ووضع السياسيات واولوية تنفذها.
              </li>
              <li>
                متابعة وتقييم وتطوير الأداء المؤسسي وتطوير الخدمات وتخطيط المشروعات وإدارة المخاطر
                والأزمات.
              </li>
              <li>
                متابعة وتقييم وتطوير الأداء الاستراتيجي للهيئة وفقًا للمعاير المعتمدة وإعداد
                التقارير بذلك.
              </li>
              <li>تنفيذ كافة المهام المرتبطة بدراسة وإدارة ومتابعة تنفيذ كافة مشروعات الهيئة.</li>
              <li>
                تنفيذ كافة الدراسات المرتبطة بتقييم المخاطر والأزمات التي قد تواجه الهيئة والاستفادة
                من النتائج في إعداد خطط وبرامج المواجهة.
              </li>
              <li>
                متابعة سير الخطط والبرامج التشغيلية للتقسيمات التنظيمية التابعة للإدارة والتأكد من
                تنفيذها وفقًا لاستراتيجية العامة للهيئة.
              </li>
              <li>
                تحقيق الأهداف الاستراتيجية ورسالة الهيئة من خلال صياغة وتنسيق ومتابعة تطبيق
                استراتيجيات وخطط وبرامج العمل.
              </li>
              <li>العمل على وجود بيئة عمل مستقرة على مستوي الهيئة.</li>
              <li>ضمان وجود أعمال منظمة ومنسقة على مستوي الهيئة.</li>
            </OrderedList>
          </BoxContainer>
        </Truncate>
      </BoxSection>
      <StepNumberContainer>
        <StepNumber>3</StepNumber>
      </StepNumberContainer>
      <Title size="h2" color="darkBlue">
        الادارة المركزية لشئون المستفيديين
      </Title>
      <Description color="darkBlue" margined>
        تهدف إدارة المستفيدين إلى تقديم أعلى مستوى ممكن من الخدمة للمستفيدين والوصول إلى أقصى قدر من
        رضا العملاء وضمان حصول المنتفع على الخدمة الطبية المطلوبة بمختلف مستويات الرعاية الصحية
        وبأعلى مستوى لجودة الخدمة الطبية.
      </Description>
      <BoxSection>
        <Truncate
          type="byHeight"
          truncateHandle={() => truncateHandle('central')}
          truncate={truncate.central}
          minLength={minLength.central}
          extendStyle={truncateStyle}
        >
          <BoxContainer
            color="darkBlue"
            extendStyle={marginTopStyle}
            title="اهداف الادارة المركزية لشئون المستفيدين"
          >
            <OrderedList>
              <li>
                رفع مستوى الوعي الخاص بالمستفيد لمعرفة حقوقه ومزايا مظلة التأمين الصحي الشامل.
              </li>
              <li>ضمان توافر الرعاية الصحية الأولية.</li>
              <li>
                ضمان توافر خدمات الرعاية الصحية المتقدمة للعلاج المتطور وكافة الخدمات الطبية
                والأدوية.
              </li>
              <li>ضمان جودة خدمة المستفيدين للحصول على الرعاية الصحية الطبية المطلوبة.</li>
            </OrderedList>
          </BoxContainer>
          <BoxContainer
            color="darkBlue"
            extendStyle={marginTopStyle}
            title="الخدمات المقدمة داخل نظام التأمين الصحي الشامل"
          >
            <StepsDescription color="darkBlue">
              تهدف الهيئة العامة للتأمين الصحي الشامل، إلى تقديم أعلى مستوى ممكن من الخدمة
              للمستفيدين والوصول إلى أقصى قدر من رضاء العملاء وذلك عن طريق التعاقد مع مقدمي خدمات
              طبية لتوفير:
            </StepsDescription>
            <OrderedListExtended>
              <li>رعاية أساسية من خلال أطباء الرعاية الأولية.</li>
              <li>العلاج بالخارج لمن يستحيل علاجه داخل البلاد.</li>
              <li>
                رعاية متقدمة تشمل الجراحات وعيادات خارجية متخصصة وعلاج الأسنان وصرف الدواء وتحاليل
                وأشعات.
              </li>
              <li>الرعاية الطبية المنزلية عند الاقتضاء.</li>
              <li>العلاج الطبيعي والأجهزة التعويضية.</li>
            </OrderedListExtended>
            <StepsDescription color="darkBlue">
              تماشياً مع رؤية الهيئة العامة للتأمين الصحي الشامل، تقدم إدارة المستفيدين خدماتها في
              لتطبيق وتنفيذ نظام التأمين الصحي الشامل من خلال:
            </StepsDescription>
            <OrderedList>
              <li>تسجيل المستفيدين</li>
              <li>دفع الاشتراكات.</li>
              <li>دفع الاشتراكاتإضافة اسر المستفيدين وتعديل البيانات.</li>
              <li>تعدد أماكن العلاج.</li>
              <li>تلقى الشكاوى عن طريق خدمة المستفيدين والعمل على حلها.</li>
              <li>عمل الاحالات الطبية.</li>
              <li>التوعية المستمرة للمستفيدين بالخدمات المقدمة.</li>
            </OrderedList>
          </BoxContainer>
        </Truncate>
      </BoxSection>
      <ParagraphHeader color="darkBlue">
        الخدمات المقدمة داخل نظام التأمين الصحي الشامل
      </ParagraphHeader>
      <Table>
        <TableHeaderRow>
          <TableHeader>الفئة</TableHeader>
          <TableHeader>الاشتراك</TableHeader>
        </TableHeaderRow>
        <TableRow>
          <TableColomn>العاملون</TableColomn>
          <TableColomn>يتم خصم %1 من الاجر</TableColomn>
        </TableRow>
        <TableRow>
          <TableColomn>أصحاب المهن الحرة</TableColomn>
          <TableColomn>
            يتم خصم %5 من الأجر التأميني او من الأجر وفقا للإقرار الضريبي أو الحد الأقصى للأجر
            التأميني ايهما أكبر
          </TableColomn>
        </TableRow>
        <TableRow>
          <TableColomn>الزوجة الغير عاملة</TableColomn>
          <TableColomn>يتم خصم %3 من أجر الزوج</TableColomn>
        </TableRow>
        <TableRow>
          <TableColomn>الابن و الابنة</TableColomn>
          <TableColomn>يتم خصم %1 من أجر العائل عن كل ابن او ابنة</TableColomn>
        </TableRow>
        <TableRow>
          <TableColomn>العمال</TableColomn>
          <TableColomn>يتم خصم %5 من الأجر التأمينى</TableColomn>
        </TableRow>
        <TableRow>
          <TableColomn>أصحاب المعاشات</TableColomn>
          <TableColomn>يتم خصم %2 من قيمة المعاش</TableColomn>
        </TableRow>
        <TableRow>
          <TableColomn>مستحقون المعاشات</TableColomn>
          <TableColomn>يتم خصم %2 من قيمة المعاش</TableColomn>
        </TableRow>
        <TableRow>
          <TableColomn>الزوجة الغير عاملة لأصحاب ومستحقون المعاشات</TableColomn>
          <TableColomn>يتم خصم %2 من قيمة المعاش</TableColomn>
        </TableRow>
        <TableRow>
          <TableColomn>الابن او الابنة او المعالون لأصحاب المعاشات ومستحقون المعاشات</TableColomn>
          <TableColomn>يتم خصم %1 من قيمة المعاش عن كل ابن او ابنة او معال</TableColomn>
        </TableRow>
      </Table>
      <ParagraphHeader color="darkBlue">اشتراكات المؤمن عليهم والمعالين</ParagraphHeader>
      <Table>
        <TableHeaderRow>
          <TableHeader>الفئة</TableHeader>
          <TableHeader>الاشتراك</TableHeader>
          <TableHeader>المُعالون</TableHeader>
        </TableHeaderRow>
        <TableRow>
          <TableColomn>
            المؤمن عليهم ومن في حكمهم الخاضعون لقانون التأمين الاجتماعي رقم 108 لسنة 1976
          </TableColomn>
          <TableColomn>
            (5%) من الأجر التأميني أو من الأجر وفقاً للإقرار الضريبي أو الحد الأقصى للأجر التأميني
            أيهما أكبر.
          </TableColomn>
          <TableColomn>
            (3%) عن الزوجة غير العاملة أو التي ليس لها دخل ثابت، (%1) عن كل مُعال أو ابن
          </TableColomn>
        </TableRow>
        <TableRow>
          <TableColomn>
            أعضاء المهن الحرة (في غير الخاضعين للقوانين المذكورة بالبندين السابقين)
          </TableColomn>
          <TableColomn>
            (5%) من الأجر التأميني أو من الأجر وفقاً للإقرار الضريبي أو الحد الأقصى للأجر التأميني
            أيهما أكبر.
          </TableColomn>
          <TableColomn>
            (3%) عن الزوجة غير العاملة أو التي ليس لها دخل ثابت، (%1) عن كل مُعال أو ابن.
          </TableColomn>
        </TableRow>
        <TableRow>
          <TableColomn>المصريون العاملون بالخارج غير الخاضعون للمادة 48 من هذا القانون</TableColomn>
          <TableColomn>
            (5%) من الأجر التأميني أو من الأجر وفقاً للإقرار الضريبي أو الحد الأقصى للأجر التأميني
            أيهما أكبر.
          </TableColomn>
          <TableColomn>
            (3%) عن الزوجة غير العاملة أو التي ليس لها دخل ثابت، (%1) عن كل مُعال أو ابن.
          </TableColomn>
        </TableRow>
        <TableRow>
          <TableColomn>
            العمال الخاضعون لقانون نظام التأمين الشامل الصادر بقانون رقم 112 لسنة 1980
          </TableColomn>
          <TableColomn>
            (5%) من الأجر التأميني فقط وبحيث لا يزيد مجموع ما يسدده الفرد عن كل الأسرة على (%7)
            وتتحمل الخزانة العامة فرق التكلفة.{' '}
          </TableColomn>
          <TableColomn>
            (3%) عن الزوجة غير العاملة أو التي ليس لها دخل ثابت، (%1) عن كل مُعال أو ابن.
          </TableColomn>
        </TableRow>
      </Table>
    </ServicesContainer>
  );
};

export default Services;
