import React from 'react';
import { Container, Image, Title, Date, Description, Link } from './Card.style';

const Card = ({
  src,
  title,
  date,
  desc,
  link,
  linkText,
  direction,
  justifyContent,
  alignItem,
  margin,
  width,
  extendStyle,
}) => {
  return (
    <Container
      direction={direction}
      justifyContent={justifyContent}
      alignItem={alignItem}
      margin={margin}
      width={width}
      extendStyle={extendStyle}
    >
      {link ? (
        <a href={link} target="_blank" rel="noreferrer">
          <Image src={src} hasImage={!!src} />
        </a>
      ) : (
        <Image src={src} hasImage={!!src} />
      )}
      {title && <Title>{title}</Title>}
      {date && <Date>{date}</Date>}
      {desc && <Description>{desc}</Description>}
      {link && (
        <Link href={link} target="_blank">
          {linkText || link}
        </Link>
      )}
    </Container>
  );
};

export default Card;
