import styled from 'styled-components/macro';

export const Container = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 8px;
  align-items: center;
`;

export const Logo = styled.img`
  width: 63px;
  height: auto;
`;
export const Slogan = styled.span`
  ${({ theme }) => theme.textSize.p3}
  color: ${({ theme }) => theme.colors.Grey};
`;
