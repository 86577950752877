import styled from 'styled-components/macro';

export const Container = styled.div`
  display: block;
  border: 1px solid ${({ theme }) => theme.colors.lightGrey};
  padding-bottom: 40px;
  ${({ theme }) => theme.media.phone`
    padding-bottom: 24px;
  `}
  ${({ extendStyle }) => extendStyle || ''}
`;
