import styled, { css } from 'styled-components/macro';

export const extendCardStyle = css`
  margin: 0 12px 56px;
  ${({ theme }) => theme.media.phone`
    width: 47%;
    margin: 0 0 40px;
    & img {
      width: 100%;
      max-width: 156px;
    }
  `};
`;

export const extendFirstAndLastCardStyle = css`
  margin: 12px 1.5% 56px;
  width: 100%;
  & p {
    width: 250px;
  }
  ${({ theme }) => theme.media.phone`
    margin: 0 0 40px;
    & img {
      width: 100%;
      max-width: 156px;
    }
    & p {
      width: 156px;
    }
  `};
`;

export const extendHeader = css`
  ${({ theme }) => theme.media.phone`
    ${theme.textSize.h3}
  `};
`;

export const extendIcons = css`
  background: ${({ bkgColor, theme }) => theme.colors[bkgColor]};
  border: 8px solid ${({ bkgColor, theme }) => theme.colors[bkgColor]}1A; // for opacity
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.04);
  border-radius: 50%;
  width: 64px;
  height: 64px;
  background-clip: padding-box;
  align-items: center;
  justify-content: center;
  margin: 0 0 8px;
  &:before {
    color: ${({ color, theme }) => theme.colors[color]};
  }
`;

export const Container = styled.div`
  margin: 96px 108px;
  ${({ theme }) => theme.media.phone`
    margin: 56px 16px;
  `};
`;

export const Title = styled.h2`
  ${({ theme }) => theme.textSize.h1}
  text-align: center;
  color: ${({ theme, color }) => theme.colors[color] || theme.colors.darkBlue};
  margin-bottom: 8px;
  ${({ theme }) => theme.media.phone`
    ${theme.textSize.h2}
  `};
  ${({ extendStyle }) => extendStyle || ''}
`;

export const Description = styled.p`
  ${({ theme }) => theme.textSize.p1}
  color: ${({ theme, color }) => theme.colors[color] || theme.colors.darkBlue};
  margin: 0 auto;
  width: 73.86%;
  ${({ theme }) => theme.media.phone`
    width: 100%;
  `};
`;

export const TitleWithMargin = styled(Title)`
  margin: 96px 0 32px;
  ${({ theme }) => theme.media.phone`
    margin: 56px 0 16px;
  `};
`;

export const List = styled.ul`
  margin: 0 auto;
  width: 65.85%;
  padding: 40px 0px;
  color: ${({ theme }) => theme.colors.darkBlue};
  border: 1px solid ${({ theme }) => theme.colors.lightGrey};
  ${({ theme }) => theme.media.phone`
    width: 100%;
    padding: 24px;
    margin: 0 auto;
    box-sizing: border-box;
  `};
`;

export const ListSubTitle = styled.h4`
  ${({ theme }) => theme.textSize.h3};
  margin-right: 40px;
  ${({ theme }) => theme.media.phone`
    margin-right: 0;
  `};
`;

export const ListItem = styled.li`
  ${({ theme }) => theme.textSize.p1};
  position: relative;
  margin: 0px 64px 0px 40px;
  ${({ theme }) => theme.media.phone`
    margin: 0 24px 0 0;
  `};
`;

export const VisionAndMission = styled.div`
  display: flex;
  width: 100%;
  ${({ theme }) => theme.media.phone`
    width: calc(100% + 32px);
    margin: 0 -16px;
    flex-direction: column;
  `};
`;

export const FlexItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  box-sizing: border-box;
  background-color: ${({ theme, bkgColor }) => theme.colors[bkgColor]};
  padding: 96px 0;
  text-align: center;
  ${({ theme }) => theme.media.phone`
    width: 100%;
    padding: 90px 40px;
  `};
`;

export const MembersWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 20px auto;
  width: 80%;
  ${({ theme }) => theme.media.phone`
    justify-content: space-around;
    width: 100%;
  `};
`;

export const truncateStyle = css`
  ${({ theme }) => theme.media.desktopAndTablet`
    & > div {
      height: initial;
    }
    & > span {
      display: none;
    }
  `}
`;
