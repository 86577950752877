import styled from 'styled-components/macro';

const TruncateContainer = styled.div`
  ${({ extendStyle }) => extendStyle || ''}
`;
export const Container = styled.div`
  height: ${({ height }) => height};
  overflow-y: hidden;
`;
export const TruncatedSign = styled.span`
  padding: 0 40px;
  display: block;
  ${({ theme }) => theme.media.phone`
    padding: 0 24px;
  `}
  ${({ theme }) => `
    color: ${theme.colors.lightBlue};
    ${theme.textSize.p1}
  `}
  margin-top: 8px;
`;

export default TruncateContainer;
