import styled from 'styled-components/macro';

export const Row = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 24px auto 0px;
  ${({ theme }) => theme.media.phone`
  margin: 32px auto 0px;
  `}
`;

export const Container = styled.div`
  ${({ theme }) => theme.layout.containerDefault};
  margin-top: 96px;
  ${({ theme }) => theme.media.phone`
    margin-top: 56px;
  `}
  /* margin-bottom: 367px; */
  // page height - header height - footer height - current margin
  min-height: calc(100vh - 96px - 177px - 96px);
`;

export const Title = styled.h2`
  ${({ theme }) => theme.textSize.h1}
  text-align: center;
  color: ${({ theme, color }) => theme.colors[color] || theme.colors.darkBlue};
  margin-bottom: 8px;
`;

export const Description = styled.p`
  ${({ theme }) => theme.textSize.p1}
  text-align: center;
  color: ${({ theme, color }) => theme.colors[color] || theme.colors.darkBlue};
  margin: 0 17%;
  ${({ theme }) => theme.media.phone`
    margin: 0 12.5%;
  `}
`;

export const DownloadLink = styled.a`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.lightBlue};
  border-radius: 28px;
  box-sizing: border-box;
  width: 232px;
  max-height: 56px;
  padding: 16px 36px;
  text-decoration: none;
  text-align: center;
  color: ${({ theme }) => theme.colors.lightBlue};
  margin: 0 8px;
`;

export const CareerLink = styled.a`
  color: ${({ theme }) => theme.colors.lightBlue};
  text-decoration: underline;
  ${({ theme }) => theme.media.phone`
    display: block;
  `}
`;
